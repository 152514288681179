<template>
<div>
  <v-dialog
    v-model="dialog"
    width="700"
    :persistent="load"
  >
    <v-card>
      <v-card-title class="">
        Costos para el Artículo:
        <v-spacer></v-spacer>
        <v-btn icon @click="dialog = false">
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="mt-5 mb-0">
        <v-data-table      
          class="cebra elevation-2 mt-2"
          :search="search"
          :headers="headers"
          :items="datos.vendedores"
          :loading="load"
          :items-per-page="-1"
          :footer-props="{'items-per-page-options':[5, 10, 15, 50, 100]}"
          dense
        >
          <template v-slot:[`item.accion`]="{item}">
            <v-btn
              small
              icon color="success" title="Agregar Vendedor"
              @click="agregarVendedor(item)">
              <v-icon>
                fas fa-check
              </v-icon>
            </v-btn>
          </template>
          <template v-slot:top>
            <v-row class="d-flex justify-end pa-2" no-gutters>
              <v-col cols="6" sm="3" xl="2">
                <SearchDataTableVue
                  v-model="search"
                />
              </v-col>
            </v-row>
          </template>
          <template v-slot:no-data>
            <v-alert
              class="mx-auto mt-4"
              max-width="400"
              type="warning"
              border="left"
              dense
              text
            >
              No se encontraron datos
            </v-alert>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-dialog>  
</div>
</template>

<script>
import SearchDataTableVue from '../../components/util/SearchDataTable.vue'

export default {
  name: 'ModalMasDeUnVendPtoVta',
  props:{
    value: Boolean,
    datos:{
      type: Object,
      default: {
        vendedores: []
      },
    }
  },
  data(){
    return{
      load: false,
      search: '',
      headers: [
        { text: 'Código', value: 'vendedor_codigo' },
        { text: 'Nombre', value: 'vendedor_nombre' },
        { text: 'Cód. Accesorios', value: 'vend_acc_codigo' },
        { text: 'Seleccionar', value: 'accion', align: 'center' },
      ]
    }
  },
  created(){

  },
  methods:{
    agregarVendedor(item){
      this.$emit('agregarVendedor',item)
    }
  },
  computed:{
    dialog: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    },
  },
  components:{
    SearchDataTableVue
  },
  watch:{
  }
}
</script>

<style>

</style>
