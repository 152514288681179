var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-row',{attrs:{"no-gutters":_vm.$vuetify.breakpoint.xs}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{attrs:{"elevation":"2","outlined":""}},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.nuevo ? 'Saldos a Favor' : 'Saldos Utilizados')+" ")]),_c('v-card-text',[(_vm.nuevo)?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[(_vm.saldos_a_favor.length > 0)?_c('v-simple-table',{staticClass:"cebra"},[_c('thead',[_c('tr',_vm._l((_vm.saldos_a_favor),function(item){return _c('th',{key:item.entidad_id,staticClass:"text-center",attrs:{"scope":"col"}},[_vm._v(" "+_vm._s(item.entidad_nombre)+" ")])}),0)]),_c('tbody',[_c('tr',_vm._l((_vm.saldos_a_favor),function(item){return _c('td',{key:item.entidad_id,staticClass:"text-center",attrs:{"scope":"col"}},[_vm._v(_vm._s(_vm.formatMoney(item.saldo)))])}),0),_c('tr',{staticClass:"font-weight-bold"},[(_vm.saldos_a_favor.length > 1)?_c('th',{attrs:{"colspan":_vm.saldos_a_favor.length-1}}):_vm._e(),_c('th',{staticClass:"text-center",staticStyle:{"font-size":"1em"},attrs:{"justify":"end"}},[_vm._v(" Total disponible: "+_vm._s(_vm.formatMoney(_vm.retornarSaldoDisponible)))])])])]):_vm._e()],1)],1):_vm._e(),(_vm.nuevo)?_c('v-row',{staticClass:"d-flex justify-center"},[_c('v-col',{staticClass:"py-4",attrs:{"cols":"6","sm":"4","md":"2"}},[_c('TextFieldMoneyVue',{attrs:{"label":"Importe","properties":{
                'hide-details': true,
                prefix: '$',
                type: 'number',
                clearable: true,
              }},model:{value:(_vm.filtro.importe),callback:function ($$v) {_vm.$set(_vm.filtro, "importe", $$v)},expression:"filtro.importe"}})],1),_c('v-col',{staticClass:"py-4",attrs:{"cols":"6","sm":"4","md":"4"}},[_c('v-autocomplete',{attrs:{"label":"Origen Saldos","item-text":"nombre","item-value":"codigo","items":_vm.entidades_deposito,"validate-on-blur":"","outlined":"","hide-details":"","dense":""},model:{value:(_vm.filtro.entidad_id),callback:function ($$v) {_vm.$set(_vm.filtro, "entidad_id", $$v)},expression:"filtro.entidad_id"}})],1),_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":"6","sm":"4","md":"4"}},[_c('v-btn',{attrs:{"color":"info","title":"Agregar saldo"},on:{"click":function($event){return _vm.agregarImporteSaldo()}}},[_vm._v("Agregar")]),_c('v-btn',{staticClass:"ml-4",attrs:{"color":"success","title":"Agregar todos los Saldos"},on:{"click":function($event){return _vm.agregarTodos()}}},[_vm._v(" Agregar todos ")])],1)],1):_vm._e(),_c('div',{staticClass:"d-flex justify-center"},[_c('v-data-table',{key:_vm.key,staticClass:"cebra mt-6 ma-6 elevation-2",style:(_vm.$vuetify.breakpoint.xs ? 'width: 100%;' : 'width:40%;'),attrs:{"order-by":"forma_cobro_nombre","headers":_vm.nuevo ? _vm.hearder : [
              { text: 'Forma de pago', value: 'forma_cobro_nombre', align: 'start', formatter: _vm.formatMoney },
              { text: 'Importe', value: 'importe', align: 'end', formatter: _vm.formatMoney }
            ],"items":_vm.saldos_usados,"hide-default-footer":"","dense":""},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-alert',{staticClass:"mx-auto mt-4",attrs:{"max-width":"400","type":"warning","border":"left","dense":"","text":""}},[_vm._v(" No hay datos de saldos registrados ")])]},proxy:true},{key:`item.importe`,fn:function({item}){return [_vm._v(" "+_vm._s(_vm.formatMoney(item.importe))+" ")]}},(_vm.nuevo)?{key:`item.acciones`,fn:function({item}){return [_c('v-btn',{attrs:{"icon":"","color":"error","title":"Eliminar"},on:{"click":function($event){return _vm.eliminarSaldo(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" fas fa-times ")])],1)]}}:null],null,true)},[_c('template',{slot:"body.append"},[_c('tr',{staticClass:"font-weight-bold"},[_c('th',{staticClass:"text-left",staticStyle:{"font-size":"1em"}},[_vm._v(" Total Saldos ")]),_c('th',{staticClass:"text-right",staticStyle:{"font-size":"1em"},attrs:{"colspan":"2"}},[_vm._v(_vm._s(_vm.formatMoney(_vm.retornaSaldosUsados)))])])])],2)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }