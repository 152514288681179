<template>
<div>
  <!-- Datos del comienzo -->
  <v-row>
    <v-col cols="12" class="">
      <v-form ref="formCobranza" class="mt-2 ml-2 me-2">
        <v-row>
          <v-col cols="6" md="3">
            Sucursal
            <v-autocomplete
              v-if="nuevo"
              v-model="filtroCobranza.sucursal_codigo"
              item-text="sucursal_nombre"
              item-value="sucursal_codigo"
              :items="sucursales"
              validate-on-blur
              outlined
              dense
              clearable
              :readonly="nuevo? false: true"
              :filled="nuevo? false: true"
              :rules="nuevo ? [rules.required] : []"
              @change="getPtosVentas()"
              hide-details
            ></v-autocomplete>
            <v-text-field
              v-if="!nuevo"
              v-model.trim="filtroCobranza.sucursal_nombre"
              readonly
              filled
              validate-on-blur
              outlined
              hide-details
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="6" md="4">
            Punto de Venta
            <v-autocomplete
              v-if="nuevo"
              v-model="filtroCobranza.pto_vta_codigo"
              item-text="pto_vta_nombre"
              item-value="pto_vta_codigo"
              :items="selectPtosVenta"
              :readonly="nuevo? false: true"
              :filled="nuevo? false: true"
              validate-on-blur
              outlined
              dense
              hide-details
              :rules="nuevo ? [rules.required] : []" 
              @change="getVendedor()"
            ></v-autocomplete>
            <v-text-field
              v-if="!nuevo"
              v-model.trim="filtroCobranza.pto_vta_nombre"
              readonly
              filled
              validate-on-blur
              outlined
              hide-details
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="6" md="5" v-if="!nuevo">
            Vendedor
            <v-text-field
              v-model.trim="filtroCobranza.vendedor"
              readonly
              filled
              validate-on-blur
              outlined
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="6" md="2" v-if="nuevo">
            Vendedor
            <v-text-field
              v-model.trim="filtroCobranza.vendedor_codigo"
              validate-on-blur
              outlined
              dense
              :rules="nuevo ? [rules.required] : []"
              @blur="getVendedoresParaModal()"
            ></v-text-field>
          </v-col>
          <v-col cols="6" md="3" v-if="nuevo">
            Nombre
            <v-text-field
              v-model.trim="filtroCobranza.vendedor_nombre"
              :rules="nuevo ? [rules.required] : []"
              readonly
              filled
              validate-on-blur
              outlined
              dense
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row  class="mt-0">
          <v-col cols="6" md="3" class="py-1">
            Recibo Emisión
            <v-text-field
              v-model.trim="filtroCobranza.recibo_emisión"
              type="number"
              :readonly="nuevo? false: true"
              :filled="nuevo? false: true"
              :rules="nuevo ? [rules.required] : []"
              validate-on-blur
              outlined
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="6" md="3" class="py-1">
            Número
            <v-text-field
              v-model.trim="filtroCobranza.numero"
              type="number"
              :readonly="nuevo? false: true"
              :filled="nuevo? false: true"
              :rules="nuevo ? [rules.required] : []"
              validate-on-blur
              outlined
              dense
            ></v-text-field>
          </v-col>
        </v-row>
      </v-form>
      <!-- Modal para agregar Vendedor --> 
      <ModalMasDeUnVendPtoVtaVue
        v-model="dialogC"
        :datos="cobranzaVendedorObj"
        @agregarVendedor="agregarVendedor"
      />
      <!-- Cliente -->
      <ClienteMayoristaVue 
        :datos="clienteParam"
        @setSelectCliente="setearCliente"
        @setearClearCliente="setClearCliente"
      />
      <!-- Saldos a Favor -->
      <SaldosFavorUtilizadosVue
        :datos="datosSaldosObj"
        @eliminarSaldo="eliminarSaldo"
      />
      
      <!-- Botón Grabrar/Anular -->
      <v-row>
        <v-col cols="12" class="d-flex justify-end align-center">
          <v-btn
            class="ml-2 me-3"
            color="error"
            @click="cerrar()"
          >
            Cancelar
          </v-btn>
          <BtnConfirmarVue
            @action="anularGrabarCobranza()"
            :color="nuevo ? 'success':'orange'"
            :icono="nuevo ? 'fas fa-save' : 'fas fa-ban'"
            dark
            :nombre="nuevo ? 'Grabar' : 'Anular'"
            :texto="nuevo ? `¿Esta seguro de grabar una cobranza por un total de ${formarMoney(retornaSaldosUsados)}?` : `¿Desea anular cobranza N° ${cobranza_id}?`" 
            clase="ma-2"
            v-if="puedeOperar"
          />
        </v-col>
      </v-row>
    </v-col>
  </v-row>

</div>
</template>

<script>
import ClienteMayoristaVue from '../../components/generales/clientes/ClienteMayorista.vue';
import BtnConfirmarVue from '../../components/util/BtnConfirmar.vue';
import { format_money, order_list_by } from '../../util/utils';
import ModalMasDeUnVendPtoVtaVue from './ModalMasDeUnVendPtoVta.vue';
import SaldosFavorUtilizadosVue from '../../components/mayorista/SaldosFavorUtilizados.vue';
import { reciboLitechA4 } from '../../util/plantillas/pdfs';
import download from 'downloadjs'

export default {
  name: 'UnaCobranza',
  data(){
    return{
      formarMoney: format_money,
      //id de la cobranza, si es nuevo vendrá en null
      cobranza_id: this.$route.params.id,
      existe: false,
      nuevo: false,
      //para panels expandibles
      panelC: 0,
      panelS: 0,
      //initMayoristaCobranza: nuevo = 1 & cobranza_id = 0
      sucursales: [],
      ptos_venta: [],
      vendedores: [],
      //initMayoristaCobranza: nuevo = 0 & cobranza_id = cobranza_id
      cobranza: [],
      cliente:[],
      saldos_usados:[],
      tipos_documento:[],
      provincias:[],
      condiciones_iva: [],
      entidades_deposito: [],
      //filtro para cobranza
      filtroCobranza:{
        sucursal_codigo: null,
        sucursal_nombre: null,
        pto_vta_codigo: null,
        pto_vta_nombre: null,
        vendedor_codigo: null,
        vendedor_nombre: null,
        vendedor:  null,
        ejecutivo_codigo: null,
        recibo_emisión: null,
        numero: null
      },
      dialogC: false,
      cobranzaVendedorObj: {
        vendedores: [],
      },
      //componente cliente
      clienteParam: {
        tienePermiso: 0, // indica si puede o no crear nuevos clientes mayoristas
        ejecutivo_codigo: 0, // codigo del ejecutivo, sirve para buscar sus clientes
        pto_vta: 0, // pto de venta asociado al ejecutivo mayorista, no al cliente
        lockCliente: false, // permite o no ingresar otro codigo de cliente
        modifica: 0, // indica si se está editando o no, dejarlo en 0 si no se esta editando
        cliente: 0, // en caso de editar, pasar este campo ya que indica el codigo del cliente, sino dejarlo en 0,
        limpiar_seccion: false, // indica si tenemos que limpiar los campos de clientes
        datosCli: {}, // lleva los datos de un cliente que ya existe, dejar en vacío si es nuevo
        nuevo: false, //indica que si es nuevo, false indica que ya existe
        miRuta: 'mayoristaCobranzas/obtenerClientesMayoristas', //ruta para donde mandar el buscar
      },
      uncliente: {},
      //getObetenerSaldosAfavor
      saldos_a_favor: [],
      //según la selección
      selectSucursales:[],
      selectPtosVenta:[],
      selectVendedores:[],
      //obj para componente Saldos-a-favor
      datosSaldosObj:{
        saldos_a_favor: [],
        saldos_usados: [],
        entidades_deposito: [],
        nuevo: false,
      },
      key:0,
      //para pdf
      pdf: null,
      pdf_nombre: '',
      puedeOperar: true,
      //Reglas
      rules:{
        required: value => !!value || 'Campo requerido',
        required_zero: value => {
          if (value == null || value == undefined) {
            return 'Campo requerido'
          } else {
            return true
          }
        }
      }
    }
  },
  created(){
    this.init();
  },
  methods:{
    async init(){
      this.$store.state.loading = true;
      if(!this.cobranza_id){ //si es nuevo
        this.nuevo = true;
        this.clienteParam.nuevo = true;
        this.datosSaldosObj.nuevo = true;
        this.datosSaldosObj.saldos_usados = [];
        const resp = await this.$store.dispatch('mayoristaCobranzas/initMayoristaCobranza',{ nuevo: 1, cobranza_id: 0 })
        this.$store.state.loading = false
        if(resp.resultado == 1){
          this.sucursales = resp.sucursales; order_list_by(this.sucursales, 'sucursal_nombre'); 
          this.ptos_venta = resp.ptos_vta; order_list_by(this.ptos_venta, 'pto_vta_nombre');
          this.vendedores = resp.vendedores; order_list_by(this.vendedores, 'vendedor_nombre');
          this.entidades_deposito = resp.entidades_deposito; order_list_by(this.vendedores, 'nombre');
          this.datosSaldosObj.entidades_deposito = this.entidades_deposito
          //si solo tiene una sucursal, un pto_vta, un vendedor, rellena los campos
          if(this.sucursales.length == 1 && this.ptos_venta.length == 1){
            this.filtroCobranza.sucursal_codigo = this.sucursales[0].sucursal_codigo;
            this.filtroCobranza.pto_vta_codigo = this.ptos_venta[0].pto_vta_codigo;
            // agregado el 16-09-2024 por MMURILLO para completar el local del vendedor
            this.selectPtosVenta = this.ptos_venta.filter(elem => elem.sucursal_codigo === this.filtroCobranza.sucursal_codigo);
            this.selectVendedores = [];
            this.getVendedor();
          }
        }
        else{
          this.$store.dispatch('show_snackbar', {
            text: resp.message,
            color: 'error'
          })
          this.puedeOperar = false
          setTimeout(() => [this.cerrar()], 3000)
        }
      }else{//si ya existe
        this.existe = true;
        const resp = await this.$store.dispatch('mayoristaCobranzas/initMayoristaCobranza',{ nuevo: 0, cobranza_id: this.cobranza_id })
        this.$store.state.loading = false
        if(resp.resultado == 1){
          this.sucursales = resp.sucursales; order_list_by(this.sucursales, 'sucursal_nombre'); 
          this.ptos_venta = resp.ptos_vta; order_list_by(this.ptos_venta, 'pto_vta_nombre');
          this.vendedores = resp.vendedores; order_list_by(this.vendedores, 'vendedor_nombre');
          this.cobranza = resp.cobranza[0];
          //copia cobranza
          this.filtroCobranza.sucursal_codigo = this.cobranza.sucursal_codigo;
          this.filtroCobranza.sucursal_nombre = this.cobranza.sucursal_nombre;
          this.filtroCobranza.pto_vta_codigo = this.cobranza.pto_vta_codigo; //pto_vta_nombre
          this.filtroCobranza.pto_vta_nombre = this.cobranza.pto_vta_nombre;
          this.filtroCobranza.vendedor_codigo = this.cobranza.vendedor_codigo;
          this.filtroCobranza.vendedor_nombre = this.cobranza.vendedor_nombre;
          this.filtroCobranza.vendedor = this.filtroCobranza.vendedor_codigo + ' - ' + this.filtroCobranza.vendedor_nombre;
          this.filtroCobranza.recibo_emisión = this.cobranza.emision;
          this.filtroCobranza.numero = this.cobranza.numero_comp;
          //copia clienta para el componente ClienteMayorista
          this.cliente = resp.cliente; 
          this.clienteParam.datosCli = this.cliente[0];
          this.clienteParam.nuevo = false;
          //copia saldos usados para el Componente Salos
          this.saldos_usados = resp.saldos_usados;
          this.datosSaldosObj.saldos_usados = this.saldos_usados;
          this.datosSaldosObj.nuevo = false;
          this.datosSaldosObj.entidades_deposito = [];
          this.datosSaldosObj.saldos_a_favor = [];
          //
          this.tipos_documento = resp.tipos_documento
          this.provincias = resp.provincias
          this.condiciones_iva = resp.condiciones_iva
        }
        else{
          this.$store.dispatch('show_snackbar', {
            text: resp.message,
            color: 'error'
          })
          this.puedeOperar = false
          setTimeout(() => [this.cerrar()], 3000)
        }
      }
    },
    //si es nuevo devuelve los ptos de venta que corresponda
    getPtosVentas(){
      this.filtroCobranza.vendedor_codigo = null;
      this.filtroCobranza.vendedor_nombre = null;

      this.clienteParam.ejecutivo_codigo = 0;
      this.clienteParam.pto_vta = 0;
      this.clienteParam.limpiar_seccion = true;

      this.limpiarSaldos();
      if(this.filtroCobranza.sucursal_codigo){
        this.selectPtosVenta = this.ptos_venta.filter(elem => elem.sucursal_codigo === this.filtroCobranza.sucursal_codigo);
        this.selectVendedores = [];
      }else{
        this.filtroCobranza.pto_vta_codigo = null;
        this.selectPtosVenta = [];
      }
    },
    //si es nuevo devuelto el vendedor correspodiente al pto de venta en cuestión 
    async getVendedor(){
      if(this.filtroCobranza.pto_vta_codigo){
        this.limpiarSaldos();
        this.clienteParam.limpiar_seccion = true;
        this.filtroCobranza.vendedor_codigo = null;
        this.filtroCobranza.vendedor_nombre = null;
        this.filtroCobranza.ejecutivo_codigo = null;
        this.clienteParam.pto_vta = this.filtroCobranza.pto_vta_codigo;
        this.clienteParam.ejecutivo_codigo = 0;

        this.$store.state.loading = true;
        const resp = await this.$store.dispatch('mayoristaCobranzas/obtenerVendedoresPtoVta',this.filtroCobranza.pto_vta_codigo)
        if(resp.resultado == 1){
          this.selectVendedores = resp.vendedores;
          if(this.selectVendedores.length == 1){
            this.filtroCobranza.vendedor_codigo = this.selectVendedores[0].vendedor_codigo;
            this.filtroCobranza.vendedor_nombre = this.selectVendedores[0].vendedor_nombre;
            this.filtroCobranza.ejecutivo_codigo = this.selectVendedores[0].ejecutivo_codigo;
            this.clienteParam.ejecutivo_codigo = this.selectVendedores[0].ejecutivo_codigo;
          }else{
            //NADA, se espera a que agregar desde el modal
            this.filtroCobranza.vendedor_codigo = null;
            this.filtroCobranza.vendedor_nombre = null;
          }
        }else{
          this.$store.dispatch('show_snackbar', {
            text: vendedoresPeticion.msj,
            color: 'error',
          })
        }
        this.$store.state.loading = false;
      }else{
        this.filtroCobranza.vendedor_codigo = null;
        this.selectVendedores = [];
      }
    },
    //si es que el tamaño de SelectVendedores > 1 (obtenido en getVendedores), prepara los datos para el modal. sino rellena los campos
    getVendedoresParaModal(){
      if(this.selectVendedores.length > 1){
        this.limpiarSaldos();
        this.filtroCobranza.vendedor_nombre = null;
        this.filtroCobranza.ejecutivo_codigo = null;
        //Si no aprieta tab o no se pierde el foco, es decir, ingresa un código que existe entonces busca
        const aux = this.selectVendedores.filter(elem => elem.vendedor_codigo == this.filtroCobranza.vendedor_codigo)
        if(aux.length == 1){
          this.filtroCobranza.vendedor_codigo = aux[0].vendedor_codigo;
          this.filtroCobranza.vendedor_nombre = aux[0].vendedor_nombre;
          this.filtroCobranza.ejecutivo_codigo = aux[0].ejecutivo_codigo;
          this.clienteParam.ejecutivo_codigo = aux[0].ejecutivo_codigo;
          //this.clienteParam.pto_vta = this.filtroCobranza.pto_vta_codigo;
          return
        }
        //
        //Sino preapara el modal y lo muestra
        this.filtroCobranza.vendedor_codigo = null;
        this.clienteParam.ejecutivo_codigo = 0;
        this.cobranzaVendedorObj = {
          vendedores: this.selectVendedores,
        }
        this.dialogC = true;
      }
    },
    //cuando los datos vienen del modal los carga en el filtro 
    agregarVendedor(item){
      this.filtroCobranza.vendedor_codigo = item.vendedor_codigo;
      this.filtroCobranza.vendedor_nombre = item.vendedor_nombre;
      this.filtroCobranza.ejecutivo_codigo = item.ejecutivo_codigo;
      this.clienteParam.ejecutivo_codigo = item.ejecutivo_codigo;
      //controla la sección
      this.clienteParam.limpiar_seccion = true;
      this.clienteParam.pto_vta = this.filtroCobranza.pto_vta_codigo;
      //cierra el modal
      this.dialogC = false;
    },
    //se rellena los campos del componentes Clientes con los datos de item
    async setearCliente(item){
      this.limpiarSaldos();
      this.uncliente = {}
      this.uncliente = item;
      this.$store.state.loading = true;
      const res = await this.$store.dispatch('mayoristaCobranzas/obtenerSaldosAfavor',this.uncliente.vendedor_codigo);
      if(res.resultado == 1){
        this.saldos_a_favor = res.saldos_favor;
        order_list_by(this.saldos_a_favor,'entidad_nombre');
        this.datosSaldosObj.saldos_usados = [];
        this.datosSaldosObj.saldos_a_favor = this.saldos_a_favor;
        this.datosSaldosObj.entidades_deposito = this.entidades_deposito;
        this.datosSaldosObj.nuevo = true;
      }
      this.$store.state.loading = false;
    },
    //limpiar el componente cliente
    setClearCliente(value){
      this.clienteParam.limpiar_seccion = value
      if (this.clienteParam.limpiar_seccion == true) this.clienteParam.limpiar_seccion = false
    },
    //Eliminar el saldo que es apretado en la row del componente de saldos
    eliminarSaldo(item){
      //elimino del array de usados
      this.datosSaldosObj.saldos_usados = this.datosSaldosObj.saldos_usados.filter(elem => elem.forma_cobro_id != item.forma_cobro_id);
      //busco y agregar en el saldos a favor
      if(item.forma_cobro_id != 0){
        let pos = this.datosSaldosObj.saldos_a_favor.map(elem => elem.entidad_id).indexOf(item.forma_cobro_id);
        this.datosSaldosObj.saldos_a_favor[pos].saldo += item.importe;
      }
    },
    limpiarSaldos(){
      this.saldos_a_favor = [];
      this.datosSaldosObj.saldos_usados = [];
      this.datosSaldosObj.saldos_a_favor = [];
    },
    //descargar recibo
    async descargarRebibo(res){
      this.pdf = await reciboLitechA4({
        cliente: res.cliente,
        encabezado: res.encabezado,
        saldos_usados: res.saldos_usados,
      })
      if(this.pdf == null){
        this.cerrar();
        return
      }
      this.pdf_nombre = `Recibo - ${res.encabezado.cobranza_id}`
      download(this.pdf, this.pdf_nombre, 'application/pdf')
      await new Promise(resolve => setTimeout(resolve, 3000))
      this.cerrar();
    },
    //Botón verde para Grabar la cobranza de abajo
    async anularGrabarCobranza(){
      if(this.nuevo){
        if(!this.$refs.formCobranza.validate()){
          return this.$store.dispatch('show_snackbar', {
            text: 'Existen campos requeridos que se encuentran vacío.',
            color: 'orange'
          })
        }
        if(!this.uncliente.vendedor_codigo){
          return this.$store.dispatch('show_snackbar', {
            text: 'El código de cliente no puede estar vacío.',
            color: 'orange'
          })
        }
        if(this.datosSaldosObj.saldos_usados.length == 0){
          return this.$store.dispatch('show_snackbar', {
            text: 'No es posible grabar una cobranza vacía.',
            color: 'orange'
          })
        }

        //preparo el campo total_efectivo
        let acu_aux = 0;
        this.datosSaldosObj.saldos_usados.forEach(elem => {
          if(elem.forma_cobro_id  == 0){ //si es EFECTIVO acumula
            acu_aux += elem.importe;
          }
        });

        //preparo el total_cobranza
        let acu = 0;
        this.datosSaldosObj.saldos_usados.forEach(elem => acu += elem.importe)
        
        //armo el objeto que paso
        let encabezado = {
          sucursal_codigo: this.filtroCobranza.sucursal_codigo,//numérico. Obligatorio.
          pto_vta_codigo: this.filtroCobranza.pto_vta_codigo,//ídem.
          vendedor_codigo: this.filtroCobranza.vendedor_codigo,//ídem.
          ejecutivo_codigo: this.filtroCobranza.ejecutivo_codigo,//ídem. Código del ejecutivo del vendedor.
          recibo_emision: this.filtroCobranza.recibo_emisión,//ídem. Emisión del recibo.
          recibo_numero: this.filtroCobranza.numero,//ídem. Nro. de Recibo.
          total_cobranza: acu,//ídem.
          total_tarjeta: 0,//ídem. Enviar este campo siempre en 0.
          total_efectivo: acu_aux,//ídem. Indica el total en efectivo de la cobranza.
        }

        //armo los detalles de cliente - saldos usados
        let detalle_cta_cte = [];
        this.datosSaldosObj.saldos_usados.forEach(elem => {
          let aux = this.entidades_deposito.filter(e => e.codigo == elem.forma_cobro_id);
          aux[0].importe = elem.importe;
          detalle_cta_cte.push({codigo: aux[0].codigo, id: aux[0].entidad_deposito_id, nombre: aux[0].nombre, importe: aux[0].importe});
        });
        
        this.$store.state.loading = true
        await this.$store.dispatch('mayoristaCobranzas/nuevoCobranza',{
          encabezado: encabezado,
          cliente: this.uncliente,
          detalle_cta_cte: detalle_cta_cte
        })
          .then(async (res) => {
            if(res.resultado == 1){
              await this.$swal.fire({icon: 'success', title: `Cobranza grabada correctamente.`, timer: 3000})
              this.cerrar();
              //manda a descargar el recibo
              const resp = await this.$store.dispatch('mayoristaCobranzas/generarPdfCobranza', res.cobranza_id)
              if(resp.resultado == 1){
                this.descargarRebibo({
                  cliente: resp.cliente[0],
                  encabezado: resp.encabezado[0],
                  saldos_usados: resp.saldos_usados,
                });
              }
            }else{
              this.$store.dispatch('show_snackbar', {
                text: res.message,
                color: 'error'
              })
            }
          })
          .catch(error => {
            this.$store.dispatch('show_snackbar', {
              text: error.message,
              color: 'error'
            })
          })
        this.$store.state.loading = false
      }else{
        this.$store.state.loading = true
        await this.$store.dispatch('mayoristaCobranzas/anularCobranza',{cobranza_id: this.cobranza_id})
          .then(async (res) => {
            if(res.resultado == 1) await this.$swal.fire({icon: 'success', title: `Cobranza anulada correctamente.`, timer: 4000})
            this.cerrar();
          })
          .catch(error => {
            this.$store.dispatch('show_snackbar', {
              text: error.message,
              color: 'error'
            })
          })
        this.$store.state.loading = false
      }
    },
    cerrar(){
      this.$router.push({ path: "/index/cobranzas" });
    }
  },
  computed:{
    retornaSaldosUsados(){
      let acu = 0;
      this.datosSaldosObj.saldos_usados.forEach(elem => acu += elem.importe)
      return parseFloat(acu.toFixed(2));
    }
  },
  components:{
    BtnConfirmarVue, ClienteMayoristaVue, ModalMasDeUnVendPtoVtaVue, SaldosFavorUtilizadosVue, BtnConfirmarVue
  }
}
</script>

<style>

</style>